<template>
    <section class="talleres-ver-taller scroll-none overflow-auto pt-3">
        <!-- info principal -->
        <div class="row mx-0">
            <div class="col-12">
                <div class="bg-f9 border br-8 br-l-50rem">
                    <div class="row mx-0 position-ab contenedor-info">
                        <el-rate v-model="workshop.calificacion" disabled show-score  :colors="['#FF9800','#FF9800','#FF9800']" text-color="#FF9800" score-template="{value}" void-color="#C0C4CC" disabled-void-color="#C0C4CC" class="rate-taller" />
                        <img :src="workshop.imagen | helper-storage" alt="" class="img-logo-op wh-129 rounded-circle position-relative">
                        <div class="col-12 col-md col-lg h-100 d-middle">
                            <div>
                                <p class="f-18 f-700 mb-1">{{ workshop.nombre }}</p>
                                <p class="f-14 f-500 mb-1">Servicios vigentes: {{ workshop.servicios_vigentes }}</p>
                                <div class="row f-14">
                                    <div class="col-auto">
                                        <p class="d-middle font-italic mb-1">
                                            <i class="icon-account" />
                                            {{ workshop.responsable }}
                                        </p>
                                    </div>
                                    <div class="col-auto">
                                        <p class="f-700">
                                            Contacto:
                                            <span class="f-300">{{ workshop.telefono }}</span>
                                        </p>
                                    </div>
                                    <div class="col-auto">
                                        <p class="f-700">
                                            Dirección:
                                            <span class="f-300">{{ workshop.direccion }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <titulo-divisor titulo="Tipos de servicios" class="mx-0" />
        <pills class="mt-4" :iterable="services_graph" label="servicio" counter="servicios_vigentes" tooltip labels/>
        <titulo-divisor titulo="Servicios vigentes" class="mx-0" />
        <div class="row listado-servicios-vigentes mx-0">
            <div v-for="data in active_services" :key="data.id" class="col-12 col-md-6 col-lg-4 col-xl-auto mb-3" @click="verServicio(data)">
                <el-tooltip :content="data.descripcion" effect="dark" placement="top">
                    <card-servicio
                    :name="data.seccion"
                    :project="data.proyecto || '-'"
                    :configuration="data.configuracion"
                    :status="data.estado"
                    :percentage="data.porcentaje"
                    />
                </el-tooltip>
            </div>
        </div>
        <titulo-divisor titulo="Historial de servicios" class="mx-0">
            <div class="row">
                <div class="col-auto px-1">
                    <input-search 
                    v-model="query" 
                    placeholder="Buscar taller" 
                    :search="beforeSearch"
                    auto-search
                    />
                </div>
                <filter-indicator :count="filterCount" @openModalFilter="toggleModalFilter" @clearFilter="beforeClearFilter" />
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" :usarPaginacion="false" alturaTabla="500px" :data="history_services" class="mx-0">
            <el-table-column label="Servicio" prop="servicio" align="center" >
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600 cr-pointer">{{ scope.row.seccion }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="proyecto">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.proyecto }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Orden de compra" prop="ordenCompra" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.orden_compra }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de inicio" prop="fechaInicio" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.created_at | helper-fecha('DD MMM. YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha final" prop="fechaFinal" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_finalizado | helper-fecha('DD MMM. YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center">
                <template slot-scope="scope">
                    {{ formatoMoneda(scope.row.valor) }}
                </template>
            </el-table-column>
            <el-table-column label="Calificación" prop="calificacion" align="center" />
        </tabla-general>
        <!-- partials -->
        <modal-lateral ref="modalFilter" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Tipo de servicio</p>
                    <el-select v-model="filter.id_servicio" placeholder="Seleccionar tipo" size="small" class="w-100">
                        <el-option v-for="item in select_service_types" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="filter.fecha_inicio" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha final</p>
                    <el-date-picker v-model="filter.fecha_fin" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_value" range :min="range.min" :max="range.max" :format-tooltip="formatTooltipRange" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="beforeClearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            workshop: 'talleres/ver/workshop',
            services_graph: 'talleres/ver/services_graph',
            active_services: 'talleres/ver/active_services',
            history_services: 'talleres/ver/history_services',
            select_service_types: 'talleres/ver/select_service_types',
            range: 'talleres/ver/range',
        }),
        filter: {
            get(){
                return this.$store.getters['talleres/ver/filter']
            },
            set(val){
                this.$store.commit('talleres/ver/set_filter', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['talleres/ver/query']
            }, 
            set(val){
                this.$store.commit('talleres/ver/set_query', val)
            }
        },
        range_value: {
            get(){
                return [
                    this.filter.valor_min,
                    this.filter.valor_max,
                ]
            },
            set(val){
                this.filter.valor_min = val[0]
                this.filter.valor_max = val[1]
            }
        },
        query: {
            get(){
                return this.$store.getters['talleres/ver/query']
            },
            set(val){
                this.$store.commit('talleres/ver/set_query', val)
            }
        },
        filterCount(){
            return this.filter.count()
        },
        id_taller(){
            return this.$route.params.id
        }
    },
    async created(){
        this.replaceBreadcumb({position: 2, breadcumb: 'talleres.ver.informacion'})
        await Promise.all([
            this.Action_get_workshop_services(this.id_taller),
            this.Action_get_workshop_active_services(this.id_taller),
            this.Action_get_workshop_service_types_and_range(this.id_taller)
        ])
        this.clearFilter()
        this.Action_get_workshop_history_services(this.id_taller)
    },
    methods:{
        ...mapActions({
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            Action_get_workshop_services: 'talleres/ver/Action_get_workshop_services',
            Action_get_workshop_active_services: 'talleres/ver/Action_get_workshop_active_services',
            Action_get_workshop_service_types_and_range: 'talleres/ver/Action_get_workshop_service_types_and_range',
            Action_get_workshop_history_services: 'talleres/ver/Action_get_workshop_history_services',
            clearFilter: 'talleres/ver/clear_filter',
        }),
        beforeSearch(){
            this.Action_get_workshop_history_services(this.id_taller)
        },
        toggleModalFilter(){
            this.$refs.modalFilter.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarMateriales(){
            await this.Action_get_workshop_history_services(this.id_taller)
            this.toggleModalFilter()
        },
        async beforeClearFilter(){
            this.clearFilter()
            await this.Action_get_workshop_history_services(this.id_taller)
        },
        verServicio(data){
            console.log(data);
            this.$router.push({ name: 'obras.vigentes', params:{id_proyecto:data.id_proyecto, id_control_piso: data.id} })
        }
    }
}
</script>

<style lang="scss" scoped>
.talleres-ver-taller{
    height: calc(100vh - 255px);
    .contenedor-info{
        height: 122px;
        .img-logo-op{
            top: -4px;
        }
        .icon-account::before{
            margin-left: 0px;
        }
    }
    .br-l-50rem{
        border-radius: 50rem 8px 8px 50rem !important;
    }
    .listado-servicios-vigentes{
        .card-servicio{
            max-width: 356px;
            .icon-history{
                &::before{
                    margin-left: 0px;
                }
            }
            .bg-porcentaje{
                background: #F1F2F4;
            }
        }
    }
}
</style>